$(document).ready(function () {
    /*
        Скрипты, используемые на всех страницах
        все, что вызывает частное поведение блоков и плагины (типа фансибокса, каруселей, слайдеров) подключается  в инлайн вставках.
     */
    /**
     * Скрытие попаппа
     */
    $(document).on('click', '.js-popup-close', function () {
        $.magnificPopup.close();
    });

    /**
     * Раскрытие меню в мобильном футере
     */
    $('.js-fmenu-toggle').on('click', function () {
        $(this).toggleClass('open')
    });

    /**
     * Переключение вкладок
     */
    var $tabs = $('.js-tabs');
    $tabs.each(function () {

        var $item = $(this).find('.tabs__item');
        var $contTabs = $(this).find('.tabs__content');

        $item.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                return;
            }
            $(this)
                .addClass('active')
                .siblings($item)
                .removeClass('active');
            setTabsActive($(this).index());
        });

        function setTabsActive(index) {
            var $itemTab = $contTabs.find('.tabs__section');
            $itemTab.siblings().removeClass('active');
            $itemTab.eq(index).addClass('active');
        }
    });
    /**
     * Маска ввода для телефона
     */
    $('.js-phone').inputmask({
        mask: '+7(999)-999-99-99',
        showMaskOnHover: false,
    });
    /**
     * Вызов модального окна регистрации
     */
    $('.js-auth-reg-popup-link').magnificPopup({
        type: 'inline',
        midClick: true,
        showCloseBtn: false,
    });

    /**
     * Вызов модального окна восстановления пароля
     */
    $('.js-go-recovery-password').magnificPopup({
        type: 'inline',
        midClick: true,
        showCloseBtn: false,
    });

    /**
     * Переключиться на авторизацию
     */
    var $authTabs = $('.js-auth-tabs');
    var $authTabsItem = $authTabs.find('.tabs__item');
    var $authTabsSection = $authTabs.find('.tabs__section');
    $('.js-go-auth').on('click', function (e) {
        e.preventDefault();
        $authTabsItem.siblings().removeClass('active');
        $authTabsItem.eq(0).addClass('active');
        $authTabsSection.siblings().removeClass('active');
        $authTabsSection.eq(0).addClass('active');
    });

    /**
     * Скролл в выпадающем меню корзины в шапке
     */
    if ($('.js-cart-dropdown-scroll').length > 0) {
        var $purchaseListScroll = new PerfectScrollbar('.js-cart-dropdown-scroll');
    }

    /**
     * Открыть главное меню
     */
    $('.js-catalog-menu-btn').on('click', function () {
        $('.main-menu').addClass('active');
        $('body').addClass('no-scroll');
    });

    /**
     * Закрыть главное меню
     */
    $(document).on('click', '.js-close-catalog-btn', function (e) {
        e.preventDefault();
        $('.main-menu *').removeClass('open');
        $('#main-menu').removeClass('active');
        $('body').removeClass('no-scroll');

    });

    /**
     * Показать субменю мебели
     */
    $('.js-open-catalog-submenu').on('click', function (e) {
        if ($(window).width() <= 768) {
            e.preventDefault();
            $('.main-menu *').removeClass('open');
            var $submenu = $(this).parent().find('.js-submenu');
            $submenu.addClass('open');
        }
    });

    /**
     * Убрать субменю мебели
     */
    $('.js-submenu-back').on('click', function (e) {
        e.preventDefault();
        $(this).parent().removeClass('open');
    });


    /**
     * Показать субменю товаров
     */
    const $linkToggle = $('.js-submenu').find('ul a:not(:only-child)');
    $linkToggle.on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('open');
    });


    // Выполнить инлайн скрипты на странице
    for (var i = 0; i < window.inlineScripts.length; i++) {
        window.inlineScripts[i]();
    }

    /**
     * Показать меню пользователя
     */
    if ($('.js-show-user-menu')) {

    }
    $('.js-show-user-menu').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var $dropdown = $(this).parent().find('.user-block__dropdown');
        $dropdown.slideDown();
    });
    $(document).on('click', function (e) {
        var $trigger = $('.user-block__dropdown');
        if ($trigger !== event.target && !$trigger.has(event.target).length) {
            $('.user-block__dropdown').removeAttr('style');
        }
    });
    /**
     *закрытие попапа важных новостей
     */
    $('.js-attention-close').on('click', function () {
        $('.attention').addClass('close');
    });


    /**
     * Модальное окно претензий
     */
    $('.js-complaints-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        showCloseBtn: false
    });
});
